<template>
  <div>
    <div
      class="topBox"
    >
      <div
        class="topBox"
        v-for="(bigItem, bigIndex) in dataArr"
        :key="bigIndex"
      >
        <div class="box1">
          <div class="linebox">
            <img class="" :src="img" alt="" />
            <p class="title">
              {{ bigItem.name}}
            </p>
            <p class="num">x{{ bigItem.num }}</p>
          </div>
        </div>
        <div
          v-for="(item, index) in bigItem.data"
          :key="index"
          :class="index !== 0 ? 'product-wrap' :' product-wrap2'"
        >
          <div class="productTop">
            <div class="item1">
              <div class="item1Img">
                <h-img
                  :imgUrl="item.img"
                  :errorImg="item.defaultImg"
                  :isUseBasePath="true"
                  class="img"
                ></h-img>
              </div>
              <div class="item1Val">
                <div class="item1Title">
                  <p v-if="item.productInfoZzprdmodel" class="productInfoZzprdmodel">
                    <span  v-if="item.productLabel && item.productLabel.indexOf('15691143850')!==-1" class="freeShipping">免运费</span>
                    <span  v-if="item.productLabel && item.productLabel.indexOf('15691143849')!==-1" class="bright">璀璨</span>
                    {{ item.productInfoZzprdmodel }}</p>
                </div>
                <p>
                  <span style="margin-right:12px;">品牌：{{ item.brand }}</span>
                  <span v-if="item.color">颜色：{{ item.color }}</span>
                </p>
                <p>体积：{{Number(item.volume).toFixed(3) }}m³</p>
              </div>
            </div>
            <div class="item2">
              <div class="item2Title">
                ￥{{ $util.getFloat(item.billPrice, 2) }}
                <span style="margin-left:5px;" v-if="item.standardPrice"
                >￥{{ $util.getFloat(item.standardPrice, 2) }}</span
                >
              </div>
              <div class="item2Item">
                <!--              <p>定价组：{{ item.priceGroupName }}</p>-->
                <div class="">
                  <p style="margin-right: 12px;">定金：￥{{ item.deposit?Number(item.deposit).toFixed(2):'0.00'}}</p>
                  <p v-if="item.rebateMoney && item.rebateMoney > 0 ">返利金：￥{{ item.rebateMoney?Number(item.rebateMoney).toFixed(2):'0.00' }}</p>
                </div>
              </div>
            </div>
            <div class="item3">
              <div class="item3Title">x{{ item.buyQty }}</div>
              <div class="item3Item">
                <div class="item3Items">
                </div>
              </div>
            </div>
            <div class="item4">
              <div class="item4Title">
                定金小计：￥{{ $util.getFloat(item.deposit * item.buyQty, 2) }}
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div-->

<!--        v-for="(item, index) in GoodsList.activityList"-->
<!--        :key="index"-->
<!--        :class="index !== 0 ? 'product-wrap' :' product-wrap2'"-->
<!--      >-->
<!--        <div style="height: 24px" ></div>-->
<!--        <div class="box1">-->
<!--          <div class="linebox">-->
<!--            <img  :src="img" alt="" />-->
<!--            <p class="title">-->
<!--              {{ item.productGroupRemark }}-->
<!--            </p>-->
<!--            <p class="num">x{{ item.packageNum }}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="productTop">-->
<!--          <div class="item1">-->
<!--            <div class="item1Img">-->
<!--             <h-img-->
<!--               class="img"-->
<!--                :imgUrl="item.img"-->
<!--                :errorImg="item.defaultImg"-->
<!--                :isUseBasePath="true"-->
<!--              ></h-img>-->
<!--            </div>-->
<!--            <div class="item1Val">-->
<!--              <div class="item1Title">-->
<!--                <p v-if="item.productName">{{ item.productName }}</p>-->
<!--              </div>-->
<!--              <p>-->
<!--                <span style="margin-right:12px;">品牌：{{ item.brand }}</span>-->
<!--                <span v-if="item.color">颜色：{{ item.color }}</span>-->
<!--              </p>-->
<!--              <p>体积：{{ item.volume?Number(item.volume).toFixed(3):'0.00'}}m³</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="item2">-->
<!--            <div class="item2Title">-->
<!--              ￥{{ $util.getFloat(item.billPrice, 2) }}-->
<!--              <span style="margin-left:5px;" v-if="item.standardPrice"-->
<!--                >￥{{ $util.getFloat(item.standardPrice, 2) }}</span-->
<!--              >-->
<!--            </div>-->
<!--            <div class="item2Item">-->
<!--              <p>定价组：{{ item.priceGroupName }}</p>-->
<!--              <div>-->
<!--                <p>定金：￥{{ item.deposit?Number(item.deposit).toFixed(2):'0.00' }}</p>-->
<!--                <p v-if="item.rebateMoney && item.rebateMoney > 0 ">返利金：￥{{ item.rebateMoney?Number(item.rebateMoney).toFixed(2):'0.00' }}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="item3">-->
<!--            <div class="item3Title">x{{ item.buyQty }}</div>-->
<!--          </div>-->
<!--          <div class="item4">-->
<!--            <div class="item4Title">-->
<!--              定金小计：￥{{ $util.getFloat(Number(item.deposit) * Number(item.buyQty), 2) }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  props: {
    item: {
      required: false
    },
    settlementItem: {
      required: false
    },
    GoodsList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      makeShure: true,
      //   dataArr: [],
      img: require("@/assets/noticeList/编组 13@2x.png"),
      dataArr:[]
    };
  },
  watch:{
    'GoodsList.activityList': {
      immediate: true,
      deep:true,
      handler(newValue) {
        if(this.GoodsList.activityList[0].discountTypeName == '组合购'){
          this.GoodsList.activityList = this.changeData(this.GoodsList.activityList)
        }
      }
    },
  },

  methods: {
    changeData() {
      var map = {},
        dest = [];
      for (var i = 0; i <this.GoodsList.activityList.length; i++) {
        var ai = this.GoodsList.activityList[i];
        if (!map[ai.productGroup]) {
          dest.push({
            productGroup: ai.productGroup,
            name: ai.productGroupRemark,
            num: ai.packageNum,
            data: [ai]
          });
          map[ai.productGroup] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.productGroup == ai.productGroup) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      this.dataArr = dest;
    },
    // changeOpened(item) {
    //   this.$set(item, "opened", !item.opened);
    //   // item.opened = !item.opened
    // },
    // // 点击取消按钮
    cancleClick(data) {
      this.$emit("cancleClick", data);
    },
    // 特购页面点击是否需要特购优惠
    handleChange(data) {
      this.makeShure = !this.makeShure;
      this.$emit("settleChose", data);
    },
    // 点击购物车
    carClick() {
      this.$emit("carClick", "");
    }
  }
};
</script>

<style scoped lang="less">
.topBox {
  position: relative;
  .box1 {
    position: absolute;
    left: 160px;
    // right: ;
    margin: 0 auto;
    margin-top: -2px;
    top: 0;
    .linebox {
      position: relative;
      width: 1156px;
      height: 38px;
      left: -168px;
      z-index:100;
      // background: #F2F3F5;
      // background-image: url('./../../assets/noticeList/编组 13@3x.png');
      img {
        width: 100%;
        height: 100%;
      }
      .title {
        position: absolute;
        color: #262626;
        font-size: 14px;
        top: 4px;
        left: 76px;
        font-weight: 500;
      }
      .num {
        position: absolute;
        top: 4px;
        right: 76px;
        width: 24px;
        height: 20px;
        border-radius: 4px;
        border: 1px solid #777777;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #262626;
        font-weight: 500;
      }
    }
  }

}
.product-wrap {
  width: 1140px;
  padding: 23px 0 20px 24px;
  margin-top: 16px;
  box-sizing: border-box;
  text-align: left;
  background: #F7F7F7;
  border-radius: 8px;
  position:relative;
  .productTop {
    display: flex;
    justify-content: flex-start;
    .item1 {
      width: 392px;
      height: 100px;
      display: flex;
      justify-content: flex-start;

      .item1Img {
        margin-right: 16px;
        width: 100px;
        height: 100px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        .img{
          width: 80px;
          height: 80px;
        }
      }
      .item1Val {
        text-align: left;
        padding-top: 5px;
        box-sizing: border-box;
        .item1Title {
          display: flex;
          justify-items: flex-start;
          align-items: center;
          .productInfoZzprdmodel{
            width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight:400;
            font-size: 14px;
            color: #262626;
            line-height: 20px;
            margin-top: 0;
          }
          .buyCar {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background: #ecf8f8;
            text-align: center;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-top: 6px;
            }
          }
        }
        p {
          margin-top: 8px;
          font-size: 12px;
          color: #777;
          line-height: 16px;
        }
      }
    }
    .item2 {
      width: 240px;
      margin-left: 20px;
      .item2Title {
        width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-wrap: nowrap;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
        padding-top: 5px;
        font-weight:400;
        box-sizing: border-box;
        span {
          text-decoration: line-through;
          font-size: 12px;
          color: #777;;
        }
      }
      .item2Item {
        font-size: 12px;
        color: #777;
        line-height: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        div  {
          display: flex;
          flex-direction: row;
        }
        p {
          margin-right: 12px;
          margin-top: 8px;
        }
      }
    }
    .item3 {
      width: 210px;
      margin-left: 24px;
      .item3Title {
        padding-top: 5px;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
      }
      .item3Item {
        font-size: 12px;
        color: #777;
        line-height: 16px;

        p {
          margin-top: 8px;
        }
        .item3Items {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          p {
            margin-right: 10px;
          }
        }
      }
    }
    .item4 {
      width: 164px;
      padding-top: 5px;
      box-sizing: border-box;
      margin-left: 24px;
      .item4Title {
        font-size: 14px;
        color: #262626;
        line-height: 20px;
        font-width: 400;
      }
      .purchase {
        font-size: 12px;
        color: #777777;
        line-height: 16px;
        cursor: pointer;
        margin-top: 8px;
      }
      .purchase:hover {
        color: #00aaa6;
      }
    }
  }

  .line {
    width: 1092px;
    height: 1px;
    // border: 1px solid #eeeeee;
    background: #eee;
    margin-top: 22px;
  }
  .settlement {
    width: 100%;
    // height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-left: 49px;
    box-sizing: border-box;
    .zhengce {
      // width: 500px;
      // height: 96px;
      padding: 0 0 5px;
      border-radius: 4px;
      // border: 1px solid #fd6a6e;
      // margin-top: 12px;
      margin-bottom: 0;
      // background-image: url(/img/right.ab515ecb.png);
      // background-position: right bottom;
      // background-size: 26px 19px;
      // background-repeat: no-repeat;
      display: flex;
      align-items: center;
      .info {
        .settleTitle {
          font-size: 14px;
          color: #777777;
          line-height: 16px;
          margin-left: 24px;
          margin-bottom: 8px;
          margin-top: 16px;
        }
        .settlementVal {
          width: 679px;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-left: 24px;
          p {
            width: 150px;
            // padding: 0 5px;
            text-align: left;
            margin-right: 20px;
            margin-top: 8px;
            font-size: 12px;
            color: #777;
            line-height: 16px;
          }
        }
      }
    }
  }
}
.product-wrap2 {
  width: 1140px;
  padding: 44px 0 16px 24px;
  box-sizing: border-box;
  text-align: left;
  background: #F7F7F7;
  border-radius: 8px;
  margin-top: 16px;
  position:relative;
  .productTop {
    display: flex;
    justify-content: flex-start;
    .item1 {
      width: 392px;
      height: 100px;
      display: flex;
      justify-content: flex-start;
      .item1Img {
        margin-right: 16px;
        width: 100px;
        height: 100px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        .img{
          width: 80px;
          height: 80px;
        }
      }
      .item1Val {
        text-align: left;
        padding-top: 5px;
        box-sizing: border-box;
        .item1Title {
          display: flex;
          justify-items: flex-start;
          align-items: center;
          p {
            width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            color: #262626;
            line-height: 20px;
            margin-top: 0;
            .freeShipping {
              margin-left: 0px;
              font-size: 12px;
              color: #fff;
              width: 42px;
              text-align: center;
              background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
              border-radius: 2px;
              font-weight: 400;
              margin-right: 4px;
              height: 16px;
              line-height: 16px;
            }
            .bright {
              font-size: 12px;
              color: #433538;
              width: 30px;
              height: 16px;
              text-align: center;
              line-height: 16px;
              background: linear-gradient(90deg, #D49879 0%, #FEF2DC 50%, #D49879 100%);
              border-radius: 2px;
              font-weight: 400;
            }
          }
          .buyCar {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background: #ecf8f8;
            text-align: center;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-top: 6px;
            }
          }
        }
        p {
          margin-top: 8px;

          font-size: 12px;
          color: #777;
          line-height: 16px;
        }
      }
    }
    .item2 {
      width: 210px;
      margin-left: 60px;
      .item2Title {
        width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-wrap: nowrap;
        font-weight: 500;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
        padding-top: 5px;
        box-sizing: border-box;
        span {
          text-decoration: line-through;
          font-size: 12px;
          color: #777;
        }
      }
      .item2Item {
        font-size: 12px;
        color: #777;
        line-height: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        div  {
          display: flex;
          flex-direction: row;
        }
        p {
          margin-right: 12px;
          margin-top: 8px;
        }
      }
    }
    .item3 {
      width: 210px;
      margin-left: 24px;
      .item3Title {
        padding-top: 5px;
        box-sizing: border-box;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
      }
      .item3Item {
        font-size: 12px;
        color: #777;
        line-height: 16px;

        p {
          margin-top: 8px;
        }
        .item3Items {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          p {
            margin-right: 10px;
          }
        }
      }
    }
    .item4 {
      width: 164px;
      padding-top: 5px;
      box-sizing: border-box;
      margin-left: 24px;
      .item4Title {
        font-weight: 500;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
      }
      .purchase {
        font-family: PingFangSC-Medium;
        font-size: 12px;
        color: #777777;
        line-height: 16px;
        cursor: pointer;
        margin-top: 8px;
      }
      .purchase:hover {
        color: #00aaa6;
      }
    }
  }

  .line {
    width: 1092px;
    height: 1px;
    // border: 1px solid #eeeeee;
    background: #eee;
    margin-top: 22px;
  }
  .settlement {
    width: 100%;
    // height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-left: 49px;
    box-sizing: border-box;
    .zhengce {
      // width: 500px;
      // height: 96px;
      padding: 0 0 0px;
      border-radius: 4px;
      // border: 1px solid #fd6a6e;
      // margin-top: 12px;
      margin-bottom: 0;
      // background-image: url(/img/right.ab515ecb.png);
      // background-position: right bottom;
      // background-size: 26px 19px;
      // background-repeat: no-repeat;
      display: flex;
      align-items: center;
      .info {
        .settleTitle {
          font-size: 14px;
          color: #777777;
          line-height: 16px;
          margin-left: 24px;
          margin-bottom: 8px;
          margin-top: 16px;
        }
        .settlementVal {
          width: 679px;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-left: 24px;
          p {
            width: 150px;
            // padding: 0 5px;
            text-align: left;
            margin-right: 20px;
            margin-top: 8px;
            font-size: 12px;
            color: #777;
            line-height: 16px;
          }
        }
      }
    }
  }
}
</style>