import http from "@/utils/request";

// 获取认购信息
export function getProinfozh(data) {
    return http({
        url: '/marketActivity/unionSaveInit.nd',
        method: 'post',
        data:data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    })
}
// 普通认购
export function save(data) {
    return http({
        url: '/marketActivity/save.nd',
        method: 'post',
        data:data
    })
}

// 套购认购
export function saveMany(data) {
    return http({
        url: '/marketActivity/saveMany.nd',
        method: 'post',
        data:data
    })
}

// 获取支付信息


export function payInfo(data) {
    return http({
      method: "get",
      url: "/marketActivity/payStatus.nd",
      params:data
    });
  }

// 组合认购
export function unionSave(data) {
    return http({
        url: '/marketActivity/unionSave.nd',
        method: 'post',
        data:data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    })
}


// 根据认购结果页面id 查询数据
export function unionPayInit(data) {
    return http({
        url: '/marketActivity/unionPayInit.nd',
        method: 'post',
        data:data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    })
}
// 确认支付
export function unionPay(data) {
    return http({
        url: '/marketActivity/unionPay.nd',
        method: 'post',
        data:data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    })
}
