import Step from '@/components/global/Step/Step.vue'
import ProductItem from './components/ProductItem.vue'
import { getProinfo, saveMany, save, getProinfozh, unionSave, unionPayInit, unionPay } from "./api";
import groupProductItem from "./components/groupProductItem"
import groupProductItemResult from "./components/groupProductItemResult"
import ProductItemResult from "./components/ProductItemResult"
export default {
  name: "Home",
  components: {
    Step,
    ProductItem,
    groupProductItem,
    ProductItemResult,
    groupProductItemResult
  },
  data() {
    return {
      modelShow: false,

      // 面包屑
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
      {
        path: "/activity",
        name: "activity",
        title: "活动专区"
      }, {
        path: "/activesubscriptionPage",
        name: "activesubscriptionPage",
        title: "认购结算"
      }
      ],
      activeName: '',// 活动名称
      activeType: '', // 活动类型
      saleType: '',// 促销方式
      startTime: '',
      invoice: '',//供应商
      billToDTOs: [],
      pageLoad: false,
      goodsNum: 0,
      goodsPrice: 0,
      goodsVolume:0,
      GoodsList: [], // 商品列表数据
      GoodsListAA:[],
      dataArr: [],
      steps: ['选择商品', '确认订单信息', '提交订单'],
      currentStep: 2,
      RouterParams: {},
      supplierId: '',// 供应商id
      isFX: '',// 该供应商是否为分销 用来判断是否显示转款协议
      isChooseNum: 0,
      proIds: [],
      buyNums: [],
      visible: false,
      cherkRow:{},// 选中的数据
      cherk: false,
      deposit: 0, // 定金价格 根据定金是否大于0判断跳转页面
      page:'',
      isLoading:false
    };
  },
  watch:{
    GoodsList: function ()  {
      if( this.GoodsList.length > 0 && this.$route.query.page == 'acivityResultPage'){
        this.goodsNum = 0
        this.goodsVolume = 0
        this.GoodsList.forEach(item=>{
          this.goodsNum += item.total
          this.goodsPrice += item.djMoney
          this.goodsVolume += Number(item.volume)
        })
      }
    }
  },
  mounted() {
    this.page = this.$route.query.page
    if(this.$route.query.page == 'acivityResultPage') {
      this.getdetailInfo()
    } else {
      this.getProinfo()
    }

  },
  methods: {
    goback() {
      window.close()
      this.$router.go(-1)
    },
    onChange(item,e) {
      item.cherk = e.target.checked
      this.$forceUpdate()
    },
    // 打开协议
    openZK(item){
      this.cherkRow = {}
      this.visible= true
      this.cherkRow = item
    },

    // 不同意协议
    disagree() {
      this.cherkRow.cherk = false
      this.$forceUpdate()
      this.visible = false
    },
    // 同意协议
    agree() {
      this.cherkRow.cherk = true
      this.$forceUpdate()
      this.visible = false
    },
    // 认购参与结果页面过来调用方法
  async  getdetailInfo(){
      this.pageLoad = true
      let all = JSON.parse(this.$route.query.listOder)
      let data = {
        list:all
      }
      unionPayInit(data).then(res=>{
        this.pageLoad = false
        this.GoodsList = res.data.list
        // this.changeDataZJHF(this.GoodsList)
        this.GoodsList.forEach(item=>{
          item.name =  item.activityName
          item.endDate = item.transferExpireDateDesc
          item.invoice = String(item.orgName)
          item.total = 0
          item.djMoney = 0
          // item.volume = 0
          // this.goodsVolume+=(Number(item.buyNum)*Number(item.volume) )
          // this.goodsNum += item.buyNum
          if(item.discountTypeName == '组合购') {
            let arr = []
            item.slaveList.forEach(item2=>{
              if(item2.statusName == '待付款') {
                item2.actPro.buyNum = item2.buyNum
                arr.push(item2.actPro)
                item.total += Number(item2.buyNum)
                item.djMoney+= (Number(item2.buyNum)*Number(item2.deposit) )
                item.volume += (Number(item2.buyNum)*Number(item2.actPro.volume) )
              }
            })
            this.changeData2(arr)
          } item.volume = 0
          if(item.discountTypeName == '套购'){
            item.slaveList.forEach(twoItem=> {
              item.total += Number(twoItem.buyNum)
              item.djMoney+= (Number(twoItem.buyNum) * Number(twoItem.deposit) )
              item.volume += (Number(twoItem.buyNum) * Number(twoItem.actPro.volume) )
            })
          }

          if(item.discountTypeName == '直降' || item.discountTypeName=='后返'){
            item.total += Number(item.buyNum)
            item.djMoney+= (Number(item.buyNum)*Number(item.deposit) )
            item.volume += (Number(item.buyNum)*Number(item.actPro.volume) )
          }

        })

      }).catch(error=>{
        this.pageLoad = false
      })
    },
    // 获取商品详情数据（活动列表页面跳转调用方法）
    async getProinfo() {
      this.pageLoad = true
      // 上个页面传过来的数据 用来获取供应商
      let all = JSON.parse(this.$route.query.listALL)
      let data = {
        data:JSON.parse(this.$route.query.listOder)
      }
      await getProinfozh(data).then(res => {
        this.pageLoad = false
        this.goodsNum = 0
        this.goodsPrice = 0
        this.goodsVolume = 0
         res.data.data.forEach(item=>{
          item.activityListNew = item.activityList
          item.name =   item.activityList[0].activityName
          item.startDate = item.activityList[0].startDate
          item.endDate = item.activityList[0].endDate
          item.discountTypeName = item.activityList[0].discountTypeName
          all.forEach(item2=>{
            if(item.activityList[0].activityId == item2.id ) {
              for (let key in item2.orgDict) {
                item.billToDTOs = item2.orgDict[key]
              }
              item.invoice = String(item.billToDTOs[0].code)
              item.isFX = item.billToDTOs[0].desc
              item.cherk = false
              this.$forceUpdate()
            }
          })
          item.total = 0
          item.djMoney = 0
          item.activityList.forEach(proItem=>{
            item.total += Number(proItem.buyQty)
            item.djMoney+=(Number(proItem.buyQty)*Number(proItem.deposit) )
            item.volume += (Number(proItem.buyQty) * Number(proItem.volume) )
            this.goodsVolume+=(Number(proItem.buyQty)*Number(proItem.volume) )
          })
          this.goodsNum += item.total
          this.goodsPrice += item.djMoney
        })
        this.GoodsList = res.data.data
      }).catch(error=>{
       this.pageLoad = false
     })
    },
    changeDataZJHF(arr) {
      var map = {},
        dest = [];
      for(var i = 0; i < arr.length; i++){
        var ai = arr[i];
        if(!map[ai.activityCode]){
          dest.push({
            activityCode: ai.activityCode,
            name: ai.name,
            data: [ai]
          });
          map[ai.activityCode] = ai;
        }else{
          for(var j = 0; j < dest.length; j++){
            var dj = dest[j];
            if(dj.activityCode == ai.activityCode){
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      // // return dest
      // console.log('dest',dest);
      this.GoodsListAA = dest
      return dest
    },
    changeData() {
      var map = {},
        dest = [];
      for (var i = 0; i < this.GoodsList.length; i++) {
        var ai = this.GoodsList[i];
        if (!map[ai.productGroup]) {
          dest.push({
            productGroup: ai.productGroup,
            name: ai.productGroupRemark,
            num: ai.packageNum,
            data: [ai]
          });
          map[ai.productGroup] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.productGroup == ai.productGroup) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      this.dataArr = dest;
    },
    // 处理组合购数据
    changeData1(arr) {
      var map = {},
        dest = [];
      for (var i = 0; i < arr.length; i++) {
        var ai = arr[i];
        if(arr[i].discountTypeName == '组合购') {
          if (!map[ai.productGroup]) {
            dest.push({
              productGroup: ai.productGroup,
              name: ai.productGroupRemark,
              num: ai.packageNum,
              data: [ai]
            });
            map[ai.productGroup] = ai;
          } else {
            for (var j = 0; j < dest.length; j++) {
              var dj = dest[j];
              if (dj.productGroup == ai.productGroup) {
                dj.data.push(ai);
                break;
              }
            }
          }
        }
      }
      this.dataArr = dest;
    },
    // 认购结果页面处理数据据
    changeData2(arr) {
      var map = {},
        dest = [];
      for (var i = 0; i < arr.length; i++) {
        var ai = arr[i];
        if(arr[i].discountTypeName == '组合购') {
          if (!map[ai.productGroup]) {
            dest.push({
              productGroup: ai.productGroup,
              name: ai.productGroupRemark,
              num: ai.packageNum,
              data: [ai]
            });
            map[ai.productGroup] = ai;
          } else {
            for (var j = 0; j < dest.length; j++) {
              var dj = dest[j];
              if (dj.productGroup == ai.productGroup) {
                dj.data.push(ai);
                break;
              }
            }
          }
        }
      }
      this.dataArr = dest;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleBlur() {

    },
    // 修改供应商
    handleChange(item,e) {
      item.invoice = e
      this.$forceUpdate()
      item.billToDTOs.forEach(itemBill => {
        if (itemBill.code ===  item.invoice) {
          item.isFx = itemBill.desc
        }
      })
      this.$forceUpdate()
    },
    handleFocus() {

    },
    // 组合认购 (活动列表页面认购接口)
    subscription() {
      let arr = []
      for(let i = 0; i< this.GoodsList.length; i ++) {
        if(this.GoodsList[i].isFX=='Y' && !this.GoodsList[i].cherk){
          this.$message.warning('请勾选《转款待到代理商》协议')
          return
        }
        let list = []
        this.GoodsList[i].activityListNew.forEach(item=>{
          list.push({
            id:item.id,
            buyNum:item.buyQty
          })
        })
        arr.push({
          activityId:this.GoodsList[i].activityListNew[0].activityId,
          orgId:this.GoodsList[i].invoice,
          list:list
        })
      }
      let data ={
        data:arr
      }
      this.isLoading = true
      unionSave(data).then(res=>{
        this.isLoading = false
        if (res && res.data.code == 0 && this.goodsPrice <= 0) {
          this.$router.push({
            path: '/submitEndActivity',
            query: {
              type:'zuhe'
            }
          })
        } else {
          if (res.data.code == 0) {
            // 跳转支付页面
            this.isLoading = false
            // 如果接口返回参数payType并且为unionPay 直接打开该页面去电子收银台页面 不走之前流程
            if(res.data.data[0].payInfo[0].payType && res.data.data[0].payInfo[0].payType=='unionPay' ) {

              // window.open(res.data.data.payInfo[0].payInfo,' _self')
              window.location.href=res.data.data[0].payInfo[0].payInfo
              return
            }
            this.$router.push({
              path: '/wxPay',
              query: {
                payInfo: res.data.data[0].payInfo[0].payInfo,
                code: res.data.data[0].orderCode
              }
            })
          } else {
            this.$message.warning(res.data.msg)
            this.isLoading = false
          }
        }
      }).catch(error=>{
        this.isLoading = false
      })
    },
  //  确认支付按钮
    gopay(){
      this.isLoading = true
      let all = JSON.parse(this.$route.query.listOder)
      let data = {
        list:all
      }
      unionPay(data).then(res=>{
        this.isLoading = false
        if (res.data.code == 0) {
          this.isLoading = false
          // 跳转支付页面
          // 如果接口返回参数payType并且为unionPay 直接打开该页面去电子收银台页面 不走之前流程
          if(res.data.payInfo && res.data.payType=='unionPay' ) {
            window.location.href=res.data.payInfo
            return
          }
          this.$router.push({
            path: '/wxPay',
            query: {
              payInfo: res.data.payInfo,
            }
          })
        } else {
          this.$message.warning(res.data.msg)
          this.pageLoad = false
        }
      }).catch(error=>{
        this.isLoading = false
      })
    }
    
  }
}